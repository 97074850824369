import maluke from "../assets/img/pedro-maluke.png";
import pesadelo from "../assets/img/pesadelo.png";
import idk from "../assets/img/idk.png";
import playerUnknown from "../assets/img/player-unknow.png";
import flagBr from "../assets/img/BR.jpg";
import bgCSGoTeam from "../assets/img/bgGameTeam.png";
import symbolCsGo from "../assets/img/csgoSymbol.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faTwitch,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Players = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      arrows: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="teams">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Age Teams</h2>
              <div className="block-teams-control">
                <div className="block-teams-img">
                  <div className="block-teams-picture">
                    <div className="block-teams-picture-wrapper">
                      <div className="picture-img">
                        <div className="picture-img-picture">
                          <img src={bgCSGoTeam} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="team-block-icon" src={symbolCsGo} />
              </div>

              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.<br></br> Lorem Ipsum has been the industry's standard
                dummy text.
              </p> */}
              <h2>CS GO</h2>
              <Carousel
                swipeable={true}
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={playerUnknown} alt="Image" />
                  <div className="player-info">
                    <div className="player-nickname">
                      <img
                        className="player-country"
                        src={flagBr}
                        alt="Brasil"
                      />
                      <span>Maluk3</span>
                    </div>
                    <div className="player-name">Pedro Cardoso</div>
                    <div className="teammate-links">
                      <nav className="teammate-links-nav">
                        <a
                          className="follow-link"
                          href="https://twitter.com/Pedro_Maluk3"
                          title="Maluke on Twitter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                          <span class="icon-fallback-text">Twitter</span>
                        </a>

                        <a
                          className="follow-link"
                          href="https://www.instagram.com/pedro_maluk3/ "
                          title="Maluk3 on Instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                          <span class="icon-fallback-text">Instagram</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img src={playerUnknown} alt="Image" />
                  <div className="plauer-info">
                    <div className="player-nickname">
                      <img
                        className="player-country"
                        src={flagBr}
                        alt="Brasil"
                      />
                      <span>Pesadelo</span>
                    </div>
                    <div class="player-name">Pesa</div>
                    <div className="teammate-links">
                      <nav className="teammate-links-nav">
                        <a
                          className="follow-link"
                          href="https://twitter.com/pesafps"
                          title="Maluke on Twitter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                          <span class="icon-fallback-text">Twitter</span>
                        </a>

                        <a
                          className="follow-link"
                          href="https://www.instagram.com/pesafps/"
                          title="Maluk3 on Instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                          <span class="icon-fallback-text">Instagram</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img src={playerUnknown} alt="Image" />
                  <div className="plauer-info">
                    <div className="player-nickname">
                      <img
                        className="player-country"
                        src={flagBr}
                        alt="Brasil"
                      />
                      <span>IDK</span>
                    </div>
                    <div class="player-name">Victor Torraca</div>
                    <div className="teammate-links">
                      <nav className="teammate-links-nav">
                        <a
                          className="follow-link"
                          href="https://twitter.com/iDkfps"
                          title="IDK on Twitter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                          <span class="icon-fallback-text">Twitter</span>
                        </a>

                        <a
                          className="follow-link"
                          href="https://www.instagram.com/idkfps/"
                          title="IDK on Instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                          <span class="icon-fallback-text">Instagram</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img src={playerUnknown} alt="Image" />
                  <div className="plauer-info">
                    <div className="player-nickname">
                      <img
                        className="player-country"
                        src={flagBr}
                        alt="Brasil"
                      />
                      <span>NKS</span>
                    </div>
                    <div class="player-name">Nikolas</div>
                    <div className="teammate-links">
                      <nav className="teammate-links-nav">
                        <a
                          className="follow-link"
                          href="https://twitter.com/nksfps1"
                          title="NKS on Twitter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                          <span class="icon-fallback-text">Twitter</span>
                        </a>

                        <a
                          className="follow-link"
                          href="https://www.instagram.com/nikolasj_/"
                          title="NKS on Instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                          <span class="icon-fallback-text">Instagram</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img src={playerUnknown} alt="Image" />
                  <div className="plauer-info">
                    <div className="player-nickname">
                      <img
                        className="player-country"
                        src={flagBr}
                        alt="Brasil"
                      />
                      <span>PRT</span>
                    </div>
                    <div class="player-name">Prt</div>
                    <div className="teammate-links">
                      <nav className="teammate-links-nav">
                        <a
                          className="follow-link"
                          href="https://twitter.com/prtfps"
                          title="PRT on Twitter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                          <span class="icon-fallback-text">Twitter</span>
                        </a>

                        <a
                          className="follow-link"
                          href="https://www.instagram.com/prtfps/"
                          title="PRT on Instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                          <span class="icon-fallback-text">Instagram</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
