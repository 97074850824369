import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import playerUnknown from "../assets/img/player-unknow.png";
import {
  faTwitter,
  faTwitch,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import flagBr from "../assets/img/BR.jpg";

export const StaffCard = () => {
  return (
    <>
      <Col xs={6} md={3} xl={3}>
        <div className="item">
          <img src={playerUnknown} alt="Image" />
          <div className="player-info">
            <div className="player-nickname">
              <img className="player-country" src={flagBr} alt="Brasil" />
              <span>Bisp0</span>
            </div>
            <div className="player-name">Pedro Barbosa</div>
            <div className="teammate-links">
              <nav className="teammate-links-nav">
                <a
                  className="follow-link"
                  href="https://twitter.com/bispa0fps"
                  title="Bispo on Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                  <span class="icon-fallback-text">Twitter</span>
                </a>

                <a
                  className="follow-link"
                  href="https://www.instagram.com/pedroebarbosaa/"
                  title="Bispo on Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  <span class="icon-fallback-text">Instagram</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </Col>

      <Col xs={6} md={3} xl={3}>
        <div className="item">
          <img src={playerUnknown} alt="Image" />
          <div className="player-info">
            <div className="player-nickname">
              <img className="player-country" src={flagBr} alt="Brasil" />
              <span>Eleito</span>
            </div>
            <div className="player-name">Vitor Estival</div>
            <div className="teammate-links">
              <nav className="teammate-links-nav">
                <a
                  className="follow-link"
                  href="https://twitter.com/vitoreleito"
                  title="Eleito on Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                  <span class="icon-fallback-text">Twitter</span>
                </a>

                <a
                  className="follow-link"
                  href="https://www.instagram.com/vitoreleito/ "
                  title="Eleito on Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  <span class="icon-fallback-text">Instagram</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};
