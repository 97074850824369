import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { StaffCard } from "./StaffCard";

export const About = () => {
  return (
    <section className="project" id="about">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility once>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Sobre</h2>
                  <p>
                    Saiba um pouquinho de quem somos e nossa história por trás
                    das câmeras. :)
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="history">
                    <Nav
                      variant="pills"
                      className="nav-pills nav-about-style mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="history">História</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="staff">Staff</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="history">
                        <Container className="history-box">
                          <p>
                            Diretamente da inSanitY de 2003, um novo time
                            renasce. Em 20 anos muita coisa mudou no cenário e
                            em nós também. Agora, não se trata mais de apenas um
                            time de Counter Strike, mas sim de uma organização
                            pronta para ascender nos e-sports e no vasto mundo
                            dos game.
                          </p>
                          <p>
                            O que não mudou foi a nossa vontade de vencer. Por
                            isso, reunimos velhos amigos, fizemos alguns novos e
                            vamos cumprir nosso objetivo: conquistar <b>TUDO</b>
                            .
                          </p>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="staff">
                        <Row>
                          <StaffCard />
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
