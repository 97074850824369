import { Col } from "react-bootstrap";
import LogoCounterStriker from "../assets/img/counter-strike.svg";
import iconTeamAge from "../assets/img/mascoteB.png";

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={12} md={12}>
      <a href="#">
        <div className="match-box">
          <div className="match-box-wrap">
            <div className="match-box-type-game">
              <img src={LogoCounterStriker} />
            </div>
            <div className="match-box-body">
              <div className="match-box-date">
                <span>17 JUN. 2023</span>
              </div>
              <div className="match-box-opponents">
                <div className="match-box-team">
                  <div className="match-box-team-icon">
                    <img src={iconTeamAge} />
                  </div>
                  <div className="match-box-team-text">AGE Team</div>
                </div>
                <div className="match-box-separator">
                  <div className="match-box-result">VS</div>
                </div>
                <div className="match-box-team">
                  <div className="match-box-team-icon">
                    <img src={iconTeamAge} />
                  </div>
                  <div className="match-box-team-text">AGE Team</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Col>
  );
};
