import { Col } from "react-bootstrap";

export const TournamentCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={4} md={3}>
      <div className="card-item-tournament">
        <a href="#">
          <div className="item-tournament-img">
            <div className="picture">
              <div className="picture-wrapper">
                <div className="picture-img">
                  <picture className="picture-picture">
                    <img
                      src="https://d3dwep9z8m8y9r.cloudfront.net/tournaments/2023/05/tournaments-381/square_banner/38438/conversions/Site_520x348-webp.webp"
                      alt="alt-image"
                    />
                  </picture>
                </div>
              </div>
            </div>{" "}
            <div className="card-item-svg">
              <img
                src="https://d3dwep9z8m8y9r.cloudfront.net/games/2021/11/games-3/logo/3156/DOTA2_small.svg"
                alt="icon"
                loading="lazy"
                class="card-item__svg-icon"
              />
            </div>
          </div>
          <div className="card-tournament-content">
            <div className="card-item-date">
              <span
                data-timezone="2023-05-27T03:00:00+03:00"
                data-format="DD MMMM YYYY"
              >
                26 Maio 2023
              </span>{" "}
              <span>-</span>{" "}
              <span
                data-timezone="2023-06-07T02:59:00+03:00"
                data-format="DD MMMM YYYY"
              >
                06 Junho 2023
              </span>
            </div>{" "}
            <div className="card-item-title">Pinnacle Cup: Malta Vibes #2</div>{" "}
            <div className="card-item-info">
              <div className="card-item-info-price">
                <span className="card-item-info-price-text">R$ 50.000,00</span>
              </div>{" "}
              <div className="card-item-info-type">Online</div>
            </div>
          </div>
        </a>
      </div>
    </Col>
  );
};
