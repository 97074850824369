import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BackForce from "../assets/img/backforce.png";
import GGbet from "../assets/img/ggbet.png";
import MarcSystem from "../assets/img/marcsystem.png";
import { Container } from "react-bootstrap";

export const Partners = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      arrows: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="partners" id="partners">
      <Container>
        <h2>Parceiros & Amigos</h2>
      </Container>
      <div className="bg-blue padding-partners">
        <Carousel
          swipeable={true}
          responsive={responsive}
          infinite={true}
          autoPlay
          transitionDuration={500}
          removeArrowOnDeviceType={["desktop"]}
          className="owl-carousel owl-theme partners-list"
        >
          <div className="partner">
            <img src={MarcSystem} alt="Logo do Parceiro 1" />
          </div>
          <div className="partner">
            <img src={GGbet} alt="Logo do Parceiro 2" />
          </div>
          <div className="partner">
            <img src={BackForce} alt="Logo do Parceiro 3" />
          </div>
          <div className="partner">
            <img src={BackForce} alt="Logo do Parceiro 1" />
          </div>
          <div className="partner">
            <img src={GGbet} alt="Logo do Parceiro 2" />
          </div>
          <div className="partner">
            <img src={BackForce} alt="Logo do Parceiro 3" />
          </div>
          <div className="partner">
            <img src={BackForce} alt="Logo do Parceiro 1" />
          </div>
          <div className="partner">
            <img src={GGbet} alt="Logo do Parceiro 2" />
          </div>
          <div className="partner">
            <img src={BackForce} alt="Logo do Parceiro 3" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};
