import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/mascoteW.png";
import navIcon3 from "../assets/img/nav-icon3.svg";
import iconDiscord from "../assets/img/discord-icon.svg";
import iconTwitter from "../assets/img/twitter-icon.svg";
import iconTikTok from "../assets/img/tiktok-icon.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img className="img-logo-footer" src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://www.instagram.com/agesportsgg/?hl=pt-br"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon3} alt="" />
              </a>
              <a
                href="https://discord.gg/FPtTSmSC"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconDiscord} alt="" />
              </a>
              <a
                href="https://twitter.com/agesports_gg"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconTwitter} alt="" />
              </a>
              <a
                href="https://www.tiktok.com/@agesportsgg"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconTikTok} alt="" />
              </a>
            </div>
            <p>Copyright 2023. Todos os direitos Reservados</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
